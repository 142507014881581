import React, { useEffect, useState } from "react";
//@ts-ignore
import pl_icon from "../images/pl.svg";
//@ts-ignore
import en_icon from "../images/en.svg";
//@ts-ignore
import de_icon from "../images/de.svg";
import { Link, useI18next } from "gatsby-plugin-react-i18next";

export default function LanguageSwitcher() {
  const { languages, originalPath, language } = useI18next();
  const [listIsOpen, setListIsOpen] = useState(false);

  useEffect(() => {
    setListIsOpen(false);
  }, [language]);

  console.log(languages);

  return (
    <div className="inline-block relative px-2">
      {/* change language button */}
      <button
        onClick={() => setListIsOpen((prev) => !prev)}
        aria-controls="languages-list"
        aria-expanded="false"
        className="group flex items-center gap-1">
        {/* flag img */}
        <div className="w-10 h-10 sm:w-8 sm:h-8 rounded-full overflow-hidden sm:border">
          <img
            className="block w-full h-full object-cover"
            src={
              language === "pl"
                ? pl_icon
                : language === "en"
                ? en_icon
                : language === "de"
                ? de_icon
                : ""
            }
            alt=""
          />
        </div>
      </button>

      {/* languages list */}
      {listIsOpen && (
        <ul className="flex flex-col items-center gap-2 absolute top-full left-0 mt-4 z-full sm:bg-neutral-100 rounded-full w-full py-3 sm:shadow-md">
          {/* <li className="block">
            <Link
              to={originalPath}
              language="de"
              className="block w-10 h-10 sm:w-8 sm:h-8 rounded-full overflow-hidden sm:border">
              <img
                className="block w-full h-full object-cover"
                src={de_icon}
                alt=""
              />
            </Link>
          </li> */}
          <li>
            <Link
              to={originalPath}
              language="en"
              className="block w-10 h-10 sm:w-8 sm:h-8 rounded-full overflow-hidden sm:border">
              <img
                className="block w-full h-full object-cover"
                src={en_icon}
                alt=""
              />
            </Link>
          </li>
          <li>
            <Link
              to={originalPath}
              language="pl"
              className="block w-10 h-10 sm:w-8 sm:h-8 rounded-full overflow-hidden sm:border">
              <img
                className="block w-full h-full object-cover"
                src={pl_icon}
                alt=""
              />
            </Link>
          </li>
        </ul>
      )}
    </div>
  );
}
