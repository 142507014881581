import * as React from "react";
//@ts-ignore
import pl_icon from "../images/pl.svg";
//@ts-ignore
import en_icon from "../images/en.svg";
//@ts-ignore
import de_icon from "../images/de.svg";
//@ts-ignore
import wi_logo_temp from "../images/wi-logo-temp.png";
import { Trans, useTranslation } from "react-i18next";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import LanguageSwitcher from "./LanguageSwitcher";
import NavLink from "./NavLink";

import { useState } from "react";

export interface LayoutProps {
  children: React.ReactNode;
}

const Layout = (props: LayoutProps) => {
  const { languages, originalPath } = useI18next();
  const { t } = useTranslation();

  const [navIsOpen, setNavIsOpen] = useState(false);

  return (
    <>
      <nav className="fixed top-0 left-0 z-50 bg-neutral-100 w-full  ">
        <div className="max-w-7xl mx-auto py-3 md:py-5 px-5 md:px-10 flex justify-between items-center ">
          {/* header with logo */}
          <header className="relative">
            <Link
              to="/"
              className="flex items-center gap-2 hover:no-underline focus:no-underline">
              <img src={wi_logo_temp} alt="" className="block w-14 md:w-20" />
              <span className="font-bold">WEimpact.AI</span>
            </Link>
          </header>

          {/* nav toggler */}
          <button
            onClick={() => setNavIsOpen((prev) => !prev)}
            className={`relative z-50 ${
              navIsOpen ? "text-white" : "text-black"
            } sm:hidden`}>
            {navIsOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className=" h-8 w-8"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </button>

          <div
            className={`flex flex-col-reverse sm:flex-row justify-end sm:justify-start items-center gap-14 sm:gap-8 fixed sm:static right-0 top-0 h-full bg-[#767aea] sm:bg-transparent pt-24 sm:pt-0 px-16 sm:px-0 transform transition-transform ${
              navIsOpen ? "translate-x-0" : "translate-x-full sm:translate-x-0"
            }`}>
            {/* language switcher */}
            <LanguageSwitcher />

            {/* nav links */}
            <ul className="flex flex-col sm:flex-row gap-6 text-white sm:text-black uppercase sm:normal-case text-right sm:text-left text-xl sm:text-base font-bold sm:font-normal">
              <li>
                <NavLink
                  onClick={() => setNavIsOpen(false)}
                  linkText={t("Career")}
                  to="/career"
                />
              </li>
              <li>
                <NavLink
                  onClick={() => setNavIsOpen(false)}
                  linkText={t("Blog")}
                  to="/blog"
                />
              </li>
              <li>
                <NavLink
                  onClick={() => setNavIsOpen(false)}
                  linkText={t("Contact")}
                  to="/contact"
                />
              </li>
            </ul>
          </div>
        </div>
      </nav>

      <main className="p-6 md:px-0 mx-auto mt-16 md:mt-24 lg:mt-28 sm:w-10/12 max-w-7xl">
        {props.children}
      </main>
    </>
  );
};

export default Layout;
