import { Link } from "gatsby-plugin-react-i18next";
import React from "react";

export interface NavLinkProps {
  to: string;
  linkText: String;
  onClick?: () => void;
}

const NavLink = ({ to, linkText, onClick }: NavLinkProps) => {
  console.log("linkText", linkText);
  console.log("to", to);
  return (
    <Link
      onClick={onClick}
      to={to}
      className="block text-inherit sm:opacity-75 sm:hover:opacity-100 sm:focus:opacity-100 hover:no-underline focus:no-underline">
      {linkText}
    </Link>
  );
};

export default NavLink;
